import { render, staticRenderFns } from "./Packageview.vue?vue&type=template&id=9166fede&scoped=true&"
import script from "./Packageview.vue?vue&type=script&lang=js&"
export * from "./Packageview.vue?vue&type=script&lang=js&"
import style0 from "./Packageview.vue?vue&type=style&index=0&id=9166fede&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9166fede",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardTitle,VDialog,VDivider,VIcon,VRow})
