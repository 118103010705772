<template>

  <v-tabs
      color="deep-purple accent-4"
      centered
  >
    <v-tab  v-if="$store.getters.hasViewPermission('product')">الباقات</v-tab>
    <v-tab v-if="$store.getters.hasViewPermission('movement')">تفعيل الباقات</v-tab>


    <v-tab-item>
      <Loading v-if="isloading"/>
      <v-container v-else>
        <v-row>
          <v-card-title>
            عروض الباقات
          </v-card-title>
          <v-btn
              icon
              color="blue"
              class="pa-10"
              @click="getAll()"

          >
            <v-icon size="40px">mdi-cached</v-icon>
          </v-btn>

        </v-row>
        <TableView :headers="headers" :contents="contentItems" buttonName=" باقة " />
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <Loading v-if="isloading"/>
      <v-container v-else>
        <v-row>
          <v-card-title>
               تفعيل الباقات
          </v-card-title>
          <v-btn
              icon
              color="blue"
              class="pa-10"
              @click="getAll()"

          >
            <v-icon size="40px">mdi-cached</v-icon>
          </v-btn>

        </v-row>
        <ActivationView :headers="headersActive" :contents="contentActive" :package-item="contentItems" :users="contentUsers"/>
      </v-container>
    </v-tab-item>

  </v-tabs>

</template>
<script>
import TableView from '../components/PackageTable.vue';
import ActivationView from '../components/ActivationView.vue';
import Loading from '@/components/Loading.vue';
import PushServicesSocket from "@/services/PushServicesSocket";
export default {
  components:{
    TableView,
    ActivationView,
    Loading
  },
  data: ()=>({

    contentItems:[],
    contentActive:[],
    contentUsers:null,
    isloading:false,

    headers: [
      {
        text: "اسم الباقة",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "نوع الباقة", value: "quota_type" },

      { text: "عدد الرسائل", value: "quota" },

      { text: "عدد ايام الصلاحية", value: "expired_date" },
      { text: "السعر بالدولار", value: "price" },
      { text: "الحالة", value: "is_active" },
      { text: "الوصف", value: "description" },



    ],
    headersActive: [
      {
        text: "تاريخ التفعيل",
        align: "start",
        value: "timestamp",
      },
      { text: "اسم الباقة", value: "pname" },
      { text: "السعر بالدولار", value: "price" },
      { text: "عدد ايام الصلاحية", value: "expired_date" },
      { text: "المستفيد", value: "username" },
      { text: "الجهاز", value: "dserial" },



    ],

  }),
  async created() {
    if(this.$store.getters.hasViewPermission('product'))
    await this.getAll();
    if(this.$store.getters.hasViewPermission('movement'))
    await this.getAllMovement();
    if(this.$store.getters.hasViewPermission('account'))
     await this.getAllUsers();
  },

  methods: {

    async getAll(){
      this.isloading = true;
      this. contentItems = await PushServicesSocket.GetData("/product/getall");
      if(this.contentItems.status)
      {
        this.contentItems = this.contentItems.data;

        this.contentItems.forEach(value =>{
          value.id = value.serial;
          return value;
        });
      }


      this.isloading = false;
    },
    async getAllMovement(){
      this.isloading = true;
      this.contentActive = await PushServicesSocket.GetData("/movement/getall");
      if(this.contentActive.status)
      {
        this.contentActive = this.contentActive.data;

        this.contentActive.forEach(value =>{
          value.id = value.serial;
          value.timestamp = value.timestamp.toString().substring(0,16);

          return value;

        });
      }

      this.isloading = false;
    },
    async getAllUsers(){
      this.isloading = true;
      this.contentUsers = await PushServicesSocket.GetData("/account/getall");
      if(this.contentUsers.status)
      {
        this.contentUsers = this.contentUsers.accounts;

      }

      this.isloading = false;
    },

  },
  mounted() {
    this.$root.$on('updatePackage', () => {
      this.getAll();
    });
    this.$root.$on('updateMovePackage', async () => {
   await   this.getAllMovement();
    });

  }
}
</script>

<style scoped>
.Packages {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 40px;
    color: rgb(167, 167, 167);
    font-weight: 600;
}
</style>