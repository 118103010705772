<template>
  <v-row justify="center">
    <v-dialog
        v-model="qrDialog"
        persistent
        max-width="80vh"
    >

      <v-card elevation="10" class="pa-2">
        <v-btn
            icon
            class="pa-5"
            @click.native="close"
        >
          <v-icon size="35">mdi-close</v-icon>
        </v-btn>
        <v-card-title class="text-h5">

          <v-row style="width: 100%;" >

            <v-btn
                v-if="$store.getters.hasDeletePermission('product')"
                icon
                color="blue"
                class="px-10"
                @click="deleteItem()"
            >
              <v-icon size="30" color="red" class="ma-5">mdi-delete</v-icon>
            </v-btn>
            <v-btn
                v-if="$store.getters.hasChangePermission('product')"
                icon
                color="blue"
                class="px-10"
                @click="updateItem()"
            >
              <v-icon size="30" color="blue" class="ma-5" >mdi-pencil</v-icon>

            </v-btn>

          </v-row>
        </v-card-title>


          <div v-for="(item, i) in headers" :key="i">
            <RowCardView :title="item.text" :body="itemSelected[item.value]"/>
            <v-divider v-if="i+1!=headers.length"/>
          </div>

      </v-card>

      <DeleteItemDialog  :url-name="'/product/delete'" :delete-controller.sync="deleteController" :item-delete="itemSelected" :token="$store.state.token"/>
      <createAccount  v-if="isUpdate" :is-dialog.sync="isUpdate"  :is-update="true" :content="itemSelected" />
    </v-dialog>
  </v-row>
</template>




<script>

import Loading from '@/components/Loading.vue';
import createAccount from "@/components/createPackage";

import RowCardView from "@/components/RowCardView";
import DeleteItemDialog from "@/components/dialogs/DeleteItemDialog";


export default {
  props:{
    headers:{},
    qrDialog: {
      default: false
    },
    itemToken:String,
    itemSelected:{},


  },
  components: {
    Loading,
    createAccount,
    RowCardView,
    DeleteItemDialog,


  },
  data () {
    return {
      deleteController:false,
      isloading: false,
      isUpdate:false,

    }
  },
  methods: {

    close() {
      this.$emit('update:qrDialog', false);
    },
    async updateItem(){
      this.isUpdate = true;

    },
   async deleteItem() {
      this.deleteController =true;
    },
  },
  mounted() {
    this.$root.$on('closeView', () => {
      this.close();
    });
  }

}
</script>

<style scoped>



.Packages {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

}
</style>